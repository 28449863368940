import React from 'react'
import {Card, Image} from 'react-bootstrap'

function Project(props) {
    const {image, title, description, repoLink, stackLogo} = props.project
    return (
        <Card
            style={{
                background: 'rgba(255, 255, 255, 0.06   )',
                borderRadius: '15px',
            }}
            className=' text-white p-2 h-100'
        >
            <Card.Header as='h4' className='p-0 my-2 d-flex justify-content-center'>
                {/*<Image*/}
                {/*    width={ 120 }*/}
                {/*    src={ image }/>*/}
                <span className={'px-2 pb-2'}>

                <Image
                    width={35}
                    src={stackLogo} rounded/>
                </span>
                <Card.Title className='text-center'>{title}</Card.Title>
            </Card.Header>
            <Card.Body className='my-2'>

                <Card.Text>{description}</Card.Text>
                {/*<Card.Text>Tech stack with icons will go here...</Card.Text>*/}
                <a href={repoLink} target={"_blank"}> <Card.Text>{repoLink}</Card.Text></a>
            </Card.Body>
        </Card>
    )
}

export default Project

