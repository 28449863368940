import React, { useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './header.css'

const Header = () => {
    const [activeKey, setActiveKey] = useState(1)

    return ( <Navbar
        sticky='top'
        collapseOnSelect
        expand='lg'
        variant='dark'
        style={ {
            backgroundColor: '#161618', borderBottom: '1px solid white', minHeight: '10vh',
        } }
        className='justify-content-center'
    >
        <Container>
            <Navbar.Brand as={ Link } to='/'><strong>SA</strong></Navbar.Brand>
            <Navbar.Toggle aria-controls='responsive-navbar-nav'/>


            <Navbar.Collapse
                id='responsive-navbar-nav'
                className='justify-content-center'
            >
                <Nav activeKey={ activeKey } navbarScroll>
                    <Nav.Item className='mx-3 my-1 '>
                        <Nav.Link
                            className='link'
                            eventKey='1'
                            as={ Link }
                            to='/'
                            onClick={ () => setActiveKey(1) }
                        >
                            Home
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='mx-3 my-1 link'>
                        <Nav.Link
                            eventKey='2'
                            as={ Link }
                            to='/education'
                            onClick={ () => setActiveKey(2) }
                        >
                            Education
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='mx-3 my-1 link'>
                        <Nav.Link
                            eventKey='3'
                            as={ Link }
                            to='/work-experience'
                            onClick={ () => setActiveKey(3) }
                        >
                            Work Experience
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='mx-3 my-1 link'>
                        <Nav.Link
                            eventKey='4'
                            as={ Link }
                            to='/projects'
                            onClick={ () => setActiveKey(4) }
                        >
                            Projects
                        </Nav.Link>
                    </Nav.Item>
                    {/*<Nav.Item className='mx-3 my-1 link'>*/}
                    {/*    <Nav.Link*/}
                    {/*        eventKey='5'*/}
                    {/*        as={ Link }*/}
                    {/*        to='/contact'*/}
                    {/*        onClick={ () => setActiveKey(5) }*/}
                    {/*    >*/}
                    {/*        Contact*/}
                    {/*    </Nav.Link>*/}
                    {/*</Nav.Item>*/}
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar> )
}

export default Header
