import {createContext} from 'react'
import seeuLogo from '../assets/university-logo.jpg'
import ykcLogo from '../assets/high-school-logo.jpg'
import ibrLogo from '../assets/elementary-school-logo.jpg'
import uniBaselLogo from '../assets/unibasel-logo.png'
import displayPicture from '../assets/sela-pic.jpeg'
import inetingLogo from '../assets/ineting-logo.jpeg'
import webFaderLogo from '../assets/webFader-logo.png'
import inspire11Logo from '../assets/inspire11Logo.jpeg'
import googleClassroomLogo from '../assets/Google_Classroom_Logo.png'
import pythonLogo from '../assets/python-logo.png'
import laravelLogo from '../assets/laravel-logo.png'
import reactLogo from '../assets/react-logo.png'
import phpLogo from '../assets/php-logo.png'
import jsLogo from '../assets/js-logo.png'
import unibasLogo from '../assets/unibas2.jpeg'
import adaptiveScale from '../assets/adaptiveScale.png'


const UserContext = createContext();

export const UserProvider = ({children}) => {

    const homepage = {
        'keySkills': [
            'Javascript',
            'Python',
            'React',
            'MySQL',
            'REST',
            'Docker',
            'Flask',
            'C++/C#',
        ],
        'heading': 'Hi! I\'m Selaudin, a detail-oriented, forward looking\n' +
            '                        software developer.',
        'cvPath': 'cv/CV.pdf',
        'displayPicture': displayPicture
    }


    const education = [
        {
            id: 1,
            organization: 'University of Basel',
            degree: `M.Sc. Computer Science, Machine Intelligence`,
            duration: '2021 - Current',
            organizationPic: uniBaselLogo,
            webLink: 'https://www.unibas.ch/de',
        },
        {
            id: 2,
            organization: 'South East European University',
            degree: `Bachelor's Degree, Computer Science, 9.67/10`,
            duration: '2017 - 2020',
            organizationPic: seeuLogo,
            webLink: 'https://www.seeu.edu.mk',
        },
        {
            id: 3,
            organization: 'Yahya Kemal College',
            degree: `Certificate for Completed Secondary Education, 5/5`,
            duration: '2013 - 2017',
            organizationPic: ykcLogo,
            webLink: 'https://www.ykc.edu.mk',
        },
        {
            id: 4,
            organization: 'Penestia',
            degree: `Certificate for Completed Elementary Education, 5/5`,
            duration: '2004 - 2013',
            organizationPic: ibrLogo,
            webLink: 'https://dibra.gov.mk/shkolla-fillore-komunale-penestia/',
        },
    ]

    const projects = [
        {
            'id': 1,
            'image': googleClassroomLogo,
            'title': 'PalEx',
            'description': 'A paleography tool that helps with visualising characters, from a given COCO json format! ',
            'repoLink': 'https://github.com/selaudin/PalEx',
            'stackLogo': reactLogo,
        },
        {
            'id': 2,
            'image': googleClassroomLogo,
            'title': 'AutoClassroom API',
            'description': 'API that takes a csv file and connect to Google Classroom API in order to generate ' +
                'classrooms in a fast way.',
            'repoLink': 'https://gitlab.com/ealili/autoclassroom-api',
            'stackLogo': pythonLogo,
        },
        {
            'id': 3,
            'image': googleClassroomLogo,
            'title': 'MrTechSpecs',
            'description': 'A front-end web application where visitors can see phone specifications.',
            'repoLink': 'https://github.com/ealili/mrtechspecs',
            'stackLogo': reactLogo,
        },
        {
            'id': 4,
            'image': googleClassroomLogo,
            'title': 'MrTechSpecs API',
            'description': 'REST api built in PHP for the MrTechSpecs project.',
            'repoLink': 'https://github.com/ealili/api',
            'stackLogo': phpLogo,
        },
        {
            'id': 5,
            'image': googleClassroomLogo,
            'title': 'Shullani Ceramica',
            'description': 'Client\'s Website that provides with ceramics and tiles.',
            'repoLink': 'http://shullani.mk',
            'stackLogo': reactLogo,
        },
        {
            'id': 5,
            'image': googleClassroomLogo,
            'title': 'NWS-Werbetechnik',
            'description': 'Client\'s Website that deals with car wrap and advertisement.',
            'repoLink': 'https://nws-werbetechnik.ch/',
            'stackLogo': jsLogo,
        },
        {
            'id': 5,
            'image': googleClassroomLogo,
            'title': 'ImperiaCars',
            'description': 'Client\'s Website that rents luxurious and supercars.',
            'repoLink': 'https://imperiacars.ch/',
            'stackLogo': jsLogo,
        },
        {
            'id': 5,
            'image': googleClassroomLogo,
            'title': 'Morchela',
            'description': 'Client\'s Website that advertises mushrooms.',
            'repoLink': 'https://www.morchela.com/',
            'stackLogo': jsLogo,
        }
    ]

    const socialMediaLinks = {
        'linkedin': 'https://www.linkedin.com/in/selaudin-agolli',
        'github': 'https://www.github.com/selaudin',
        'instagram': 'https://www.instagram.com/selaudinn',
        'facebook': 'https://www.facebook.com/selaudin.agolli'
    }

    const workExperiences = [
        {
            'id': 1,
            'companyName': 'University of Basel',
            'companyURL': 'http://www.unibas.com/',
            'companyLogo': unibasLogo,
            'jobDescription': 'Tutor of "Operating System" lecture.',
            'duration': '4 months',
            'position': 'Tutor'
        },
        {
            'id': 2,
            'companyName': 'University of Basel',
            'companyURL': 'https://www.unibas.com',
            'companyLogo': unibasLogo,
            'jobDescription': 'Reuniting fragments, identifying scribes and characterising scripts: the Digital paleography of Greek and Coptic papyri.',
            'duration': '2 years',
            'position': 'Junior Software Developer'
        },
        {
            'id': 3,
            'companyName': 'University of Basel',
            'companyURL': 'https://www.unibas.com',
            'companyLogo': unibasLogo,
            'jobDescription': 'Tutor of "Operating System" lecture.',
            'duration': '4 months',
            'position': 'Tutor'
        },
        {
            'id': 4,
            'companyName': 'Inspire11',
            'companyURL': 'https://www.inspire11.com',
            'companyLogo': inspire11Logo,
            'jobDescription': 'Developed an API and front-end to manage Google Classroom classes.',
            'duration': '8 months',
            'position': 'Junior Software Developer'
        },
        {
            'id': 5,
            'companyName': 'AdaptiveScale',
            'companyURL': 'http://www.adaptivescale.com/',
            'companyLogo': adaptiveScale,
            'jobDescription': 'Participated in learning and development programs to acquire competencies and professional skills.',
            'duration': '3 months',
            'position': 'Intern'
        },
        {
            'id': 6,
            'companyName': 'AdaptiveScale',
            'companyURL': 'http://www.adaptivescale.com/',
            'companyLogo': adaptiveScale,
            'jobDescription': 'Developed a 2D application for children in elementary school to compute geometrical calculations for different kinds of shapes.',
            'duration': '3 months',
            'position': 'Intern'
        }
    ]

    return (
        <UserContext.Provider
            value={{
                homepage,
                education,
                socialMediaLinks,
                displayPicture,
                projects,
                workExperiences
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export default UserContext