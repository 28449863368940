import React from 'react'
import {Card, Image} from 'react-bootstrap'

function WorkExperience(props) {
    const {
        companyName,
        companyLogo,
        jobDescription,
        companyURL,
        duration,
        position
    } = props.workExperience
    return (
        <Card
            style={{
                background: 'rgba(255, 255, 255, 0.06   )',
                borderRadius: '15px',
            }}
            className=' text-white p-2 h-100'
        >
            <Card.Header as='h4' className='py-3 my-2 d-flex justify-content-center'>
                <span className={'px-2'}>
                <Image
                    width={40}
                    src={companyLogo} rounded/>
                </span>
                {companyName}
            </Card.Header>
            <Card.Body className='my-2'>
                <Card.Text>{jobDescription}</Card.Text>
                <a href={companyURL} target={'_blank'}> <Card.Text>{companyURL}</Card.Text></a>
            </Card.Body>
        </Card>
    )
}

WorkExperience.defaultProps = {
    companyBrad: '',
    companyName: 'Company Name',
    jobDescription: 'Job description',
    companyURL: 'Company URL'
}

export default WorkExperience

